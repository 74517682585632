@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.custom-button {
  display: flex;
  width: 109px;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Grad1, #214ded);
  background: var(--Grad1, linear-gradient(180deg, #214ded 0%, #b92f2c 100%));
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);

  color: #fff;

  text-align: center;
  font-family: Quicksand;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 184.615% */
}

/* styles.css */

.light-button {
  display: flex;
  width: max-content;
  height: max-content;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Grad1, #214ded);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  background: var(--Grad1, linear-gradient(180deg, #214ded 0%, #b92f2c 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.td-text {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.td {
  width: 113px;
  height: 30px;
  flex-shrink: 0;
}

.title {
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  background: var(--Grad1, linear-gradient(180deg, #214ded 0%, #b92f2c 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grey-text {
  color: var(--elk, #030b0d);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}
